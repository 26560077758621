<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'
import { addConcat } from '@/api/new'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))

const checkedtype = ref(langvalue.value.top.ten)
const type = ref(langtype)

let name = ref() // 姓名
let c_name = ref() // 公司
let email = ref() // 邮箱
let phone = ref() // 电话
let message = ref() // 客户留言
let tips = ref(false)
let text = ref('')

const sumit = async () => {
  text.value = langvalue.value.form.six
  if (!name.value) {
    tips.value = true
    setTimeout(() => {
      tips.value = false
    }, 2000)
    return
  } else if (!c_name.value) {
    tips.value = true
    setTimeout(() => {
      tips.value = false
    }, 2000)
    return
  } else if (!email.value) {
    tips.value = true
    setTimeout(() => {
      tips.value = false
    }, 2000)
  } else if (!phone.value) {
    tips.value = true
    setTimeout(() => {
      tips.value = false
    }, 2000)
    return
  } else if (!message.value) {
    tips.value = true
    setTimeout(() => {
      tips.value = false
    }, 2000)
    return
  }
  let params = {
    name: name.value,
    company: c_name.value,
    email: email.value,
    phone: phone.value,
    remark: message.value
  }
  await addConcat(params)
  name.value = ''
  c_name.value = ''
  email.value = ''
  phone.value = ''
  message.value = ''
  text.value = langvalue.value.form.seven
  tips.value = true
    setTimeout(() => {
      tips.value = false
    }, 2000)
}
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).top.ten
    type.value = newVal
  },
  { immediate: true }
)
</script>

<template>
  <div class="a_detail">
    <AboutBanner :checkedtype="checkedtype" title="CONTACT US" />
    <div class="center">
      <div class="row">
        <div class="item">
          <div class="_item_title">
            <img class="img" src="@/assets/images/input.png" />
            <span class="name">{{ langvalue.form.one }}</span>
          </div>
          <input class="input" v-model="name" />
        </div>
        <div class="item">
          <div class="_item_title">
            <img class="img" src="@/assets/images/input.png" />
            <span class="name">{{ langvalue.form.two }}</span>
          </div>
          <input class="input" v-model="c_name" />
        </div>
      </div>
      <div class="row">
        <div class="item">
          <div class="_item_title">
            <img class="img" src="@/assets/images/input.png" />
            <span class="name">{{ langvalue.form.three }}</span>
          </div>
          <input class="input" type="email" v-model="email" />
        </div>
        <div class="item">
          <div class="_item_title">
            <img class="img" src="@/assets/images/input.png" />
            <span class="name">{{ langvalue.form.four }}</span>
          </div>
          <input class="input" v-model="phone" maxlength="11" />
        </div>
      </div>
      <div class="row">
        <div class="item">
          <div class="_item_title">
            <div class="img"></div>
            <span class="name">{{ langvalue.form.five }}</span>
          </div>
          <textarea class="textarea" v-model="message"></textarea>
        </div>
      </div>
      <div class="footer_btn">
        <div class="btn" @click="sumit">提交</div>
        <div class="tips" v-if="tips">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.a_detail {
  width: 100%;
  background: #f8f8f8;

  .center {
    box-sizing: border-box;
    width: 1340px;
    margin: 0 auto;
    padding: 100px 100px 200px;
    background: #fff;
    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      .item {
        flex: 1;

        ._item_title {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          .img {
            width: 18px;
            height: 18px;
            margin-right: 12px;
          }
          .name {
            vertical-align: middle;
            color: #000;

            font-family: 'PingFang SC';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1px;
          }
        }
        .input {
          width: 100%;
          height: 65px;
          padding: 8px;
          border-radius: 11px;
          border: 1px solid #dcdcdc;
          background: #fff;
          font-size: 20px;
        }

        .textarea {
          padding: 10px;
          width: 100%;
          height: 200px;
          font-size: 20px;
          border-radius: 11px;
          border: 1px solid #dcdcdc;
          background: #fff;
        }
      }
      .item + .item {
        margin-left: 100px;
      }
    }
    .footer_btn {
      margin-top: 62px;
      display: flex;
      justify-content: center;
      position: relative;
      .btn {
        color: #fff;
        font-family: 'PingFang SC';
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.75px;
        width: 320px;
        height: 70px;
        text-align: center;
        line-height: 70px;
        border-radius: 20px;
        background: linear-gradient(103deg, #0185ff 12.97%, #01c1ff 77.15%);
      }
      .tips {
        position: absolute;
        padding: 4px 10px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        top: -40px;
      }
    }
  }
}
</style>
